var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "px-lg-5", attrs: { fluid: "" } }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("app-modal", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalVisible,
              expression: "isModalVisible"
            }
          ],
          attrs: { modal: _vm.modal },
          on: {
            "submit-modal": _vm.submitModal,
            "selection-changed": _vm.onOfficeChanged
          }
        }),
        _c("app-header", {
          attrs: {
            title: _vm.title,
            breadcrumbs: _vm.breadcrumbs,
            actionButton: _vm.actionButton,
            showButton: true
          },
          on: { clicked: _vm.openCreateAssetModal }
        }),
        _c(
          "v-card",
          { staticClass: "rounded-card", attrs: { flat: "", outlined: "" } },
          [
            _c("assets-table", {
              attrs: {
                assets: _vm.assets,
                loading: _vm.loading,
                showActions: true
              },
              on: {
                editAsset: _vm.editAsset,
                deleteAsset: _vm.deleteAsset,
                updateOptions: _vm.updateOptions
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }