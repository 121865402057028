var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    style: { cursor: _vm.isAdmin ? "" : "pointer" },
    attrs: {
      headers: _vm.headers,
      items: _vm.assets.items,
      options: _vm.options,
      "server-items-length": _vm.assets.count,
      loading: _vm.loading,
      "no-data-text": _vm.$t("messages.emptyState", {
        entity: _vm.$tc("models.asset.entity")
      }),
      "no-results-text": _vm.$t("messages.emptyState", {
        entity: _vm.$tc("models.asset.entity")
      }),
      flat: ""
    },
    on: {
      "update:options": [
        function($event) {
          _vm.options = $event
        },
        _vm.updateOptions
      ]
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item.assetType",
          fn: function(ref) {
            var item = ref.item
            return [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "models.asset.attributes.assetTypes." + item.assetType
                    )
                  ) +
                  " "
              )
            ]
          }
        },
        {
          key: "item.assigneeOffice",
          fn: function(ref) {
            var item = ref.item
            return [_vm._v(" " + _vm._s(item.assigneeOffice.value) + " ")]
          }
        },
        {
          key: "item.assigneeUser",
          fn: function(ref) {
            var item = ref.item
            return [
              _vm._v(
                " " +
                  _vm._s(item.assigneeUser ? item.assigneeUser.value : "") +
                  " "
              )
            ]
          }
        },
        {
          key: "item.actions",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.editAsset(item)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-pencil")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.deleteAsset(item)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-delete")])],
                1
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }